#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    margin: 0;
    font-size: 1em;
  }
}

.app-container {
  padding-bottom: 48px;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.btn {
  background-image: var(--bs-gradient) !important;
  &.bg-no-gradient {
    background-image: none !important;
  }
}

.home-header {
  padding: 1rem 0;
  h2 {
    font-size: var(--sm);
    font-weight: bold;
    margin: 0;
  }
  img {
    border-radius: 30rem;
    overflow: hidden;
  }
}

.list-order-container {
  margin-bottom: 2rem;
  h3 {
    margin-bottom: 0.5rem;
    line-height: 24px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: var(--xs);
    position: relative;
    color: var(--silver);
    display: flex;
    align-items: center;
    .fa {
      margin-right: 0.5rem;
      display: none;
    }
  }
}
.list-order-item {
  margin: 0;
  padding: 0;
  .order-item {
    display: flex;
    align-items: center;
    + .order-item {
      margin-top: 1rem;
    }
    .order-item-image {
      width: 48px;
      img {
        width: 48px;
        height: 48px;
        overflow: hidden;
        object-fit: cover;
        border-radius: 8px;
      }
      margin-right: 1rem;
    }
    .order-item-description {
      flex: 1;
      h4 {
        margin: 0;
        font-size: var(--md);
        a {
          color: var(--black);
          text-decoration: none;
        }
      }
    }
    .order-item-info {
      margin-left: 1rem;
      align-self: start;
      h4,
      h5 {
        font-size: var(--sm);
        font-weight: bold;
        margin: 0;
      }
      h5 {
        color: var(--orange);
        .fa {
          color: var(--yellow);
        }
      }
    }
  }
}

.internal-header {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  padding: 16px 8px;
  margin-top: 0;
  background: url(../images/bg-luokki-header.jpg) no-repeat bottom right;
  background-size: cover;
  box-shadow: 0 0 16px #f3f6f9;
  .back-button {
    a,
    button {
      appearance: none;
      border: none;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      padding: 0;
      text-decoration: none;
      font-size: var(--lg);
      text-align: center;
      color: var(--black);
    }
  }
  .header-content {
    padding: 0 8px;
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
  .header-title {
    h2 {
      font-size: var(--md);
      margin: 0;
    }
    h3 {
      font-size: var(--sm);
      margin: 0;
    }
  }
  .header-additional {
    h4,
    h5 {
      margin: 0;
    }
    h4 {
      text-align: right;
    }
  }
  .search-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    input {
      flex: 1;
      margin: 0;
    }
    .btn {
      border-radius: 30rem;
      margin-left: 0.5rem;
    }
  }
}

.social-media {
  margin-bottom: 2rem;
  a {
    font-size: var(--xxl);
    color: var(--purple);
  }
}

.error-page {
  text-align: center;
  .error-image {
    margin-bottom: 1rem;
  }
  h2 {
    font-size: var(--xl);
    font-weight: bold;
  }
  padding: 1rem 0;
}

.main-content {
  h3 {
    font-size: var(--lg);
    font-weight: bold;
  }
}

.logo {
  img {
    max-height: 52px;
  }
}
.main-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 25;
  background: #fff;
  &.fixed-bottom {
    bottom: 0;
    position: fixed;
  }
  .menu-item {
    flex: 1;

    + .menu-item {
      border-left: 1px solid #eee;
    }
    a {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      font-family: "Cabin", "Source Sans Pro", "Montserrat", "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      span {
        display: block;
        line-height: 1.2;
      }
      .menu-icon {
        flex: 1;
        font-size: var(--lg);
      }
      &:not(.btn) {
        display: block;
        text-align: center;
        text-decoration: none;
        height: 48px;
        flex-direction: column;
        padding: 0.5rem 0;
        font-size: var(--xxs);
        color: var(--silver);
        background: var(--bs-light);
        background-image: var(--bs-gradient);
      }
      &.btn {
        display: flex;
        justify-content: center;
        margin: 0.25rem;
        width: auto;
        .menu-icon {
          margin-right: 0.5rem;
        }
      }
    }
    &.active {
      a {
        &:not(.btn) {
          background: var(--bs-primary);
          color: var(--bs-light);
          .menu-icon {
            color: var(--bs-light);
          }
        }
      }
    }
  }
}

.cover-image {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  img {
    width: 120px;
    border-radius: 300px;
  }
}

.edit-cover-image {
  img {
    border-radius: 300px;
    overflow: hidden;
  }
}

.user-social-link {
  display: flex;
  .social-link-item {
    + .social-link-item {
      margin-left: 0.5rem;
    }
    .item-icon {
      margin-left: 0.25rem;
    }
    a {
      text-decoration: none;
      color: var(--primary);
    }
  }
}

.carousel-wrapper {
  overflow: hidden;
  overflow-x: auto;
}
.my-horse-container {
  width: auto;
  display: flex;
  flex-wrap: nowrap;
  padding: 1rem 0.5rem;
  background: #f8f9fa;
  background-image: var(--bs-gradient);
  border-radius: 0 0 4px 4px;
  .my-horse-item {
    width: 40%;
    flex: 1 0 40%;
    padding: 0 0.25rem;
    a {
      color: var(--black);
      text-decoration: none;
      text-align: center;
    }
    .horse-image {
      img {
        border-radius: 4px;
      }
    }
    .card-text {
      display: block;
      font-size: var(--xs);
    }
  }
}

.services-container {
  h4 {
    font-size: var(--md);
    font-weight: bold;
  }
  ul {
    display: block;
    margin: 0 0 1rem;
    padding: 0;
    li {
      display: block;
    }
  }
}

label.card-selection {
  .card-body {
    transition: all ease 0.3s;
  }
  input {
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    opacity: 0;
    &:checked {
      ~ .card-body {
        background: var(--primary);
        color: var(--white);
      }
    }
  }
}

.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.main-header {
  background-image: url(../images/bg-luokki-header.jpg);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: cover;
  // background: rgba(255, 255, 255, 0.6);
  backdrop-filter: saturate(180%) blur(3px);
}

.map-marker {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 200px;
  .icon {
    font-size: 24px;
  }
  .text {
    font-size: 10px !important;
  }
}

.image-uploader {
  overflow: hidden;
}

.w-24 {
  width: 24px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}

.label-text {
  position: absolute;
  top: 8px;
  right: 0;
  opacity: 75%;
}

.role-label {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  padding: 0 6px;
  color: #000;
  border-radius: 0 4px 0 4px;
  font-weight: bold;
}

.info-box {
  small {
    &.label {
      font-size: var(--xs);
      width: 30%;
      white-space: nowrap;
      overflow: hidden;
      text-transform: uppercase;
    }
  }
  h6 {
    padding-left: 1rem;
    flex: 1;
    margin: 0;
  }
}

.app-filter-view {
  border-bottom: 1px solid #ccc;
  .btn {
    outline: none;
    box-shadow: none;
    position: relative;
    bottom: -1px;
    border-bottom: 4px solid transparent;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0;
    &.active {
      color: var(--primaryActive);
      border-bottom-color: var(--primary);
    }
  }
}

.app-filter-sort {
  flex: 1;
}

.favorite-link {
  position: relative;
  .btn {
    position: absolute;
    right: 1rem;
    top: -20px;
    height: 40px;
    background: #fff;
    line-height: 40px;
    padding: 0;
    width: 40px;
    text-align: center;
    font-size: 20px;
    border-radius: 300px;
    color: #aaa;
    &.active {
      color: var(--red);
    }
    &:active {
      animation: pulse 300ms ease-in-out 1;
    }
  }
}

.add-gallery {
  label {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      color: var(--primary);
      font-size: 24px;
    }
    input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
    }
  }
}

.calendar-widget {
  .calendar-th {
    height: 38px;
    line-height: 38px;
  }
}

.c-row {
  .left-col {
    width: 64px;
  }
  .calendar-date {
    flex: 1;
    font-size: var(--xs);
  }
  .c-head {
    text-align: center;
    line-height: 32px;
  }
  .c-cell {
    height: 64px;
    line-height: 64px;
    text-align: center;
    flex: 1;
    &.left-col {
      flex: 0 0 64px;
      padding-right: 4px;
    }
    .c-cell-item {
      width: 100%;
      height: 32px;
      &.cell-border {
        border-top: 1px dotted #eee;
      }
    }
  }
}

.c-cols {
  position: absolute;
  top: 0;
  left: 64px;
  right: 0;
  bottom: 0;
  display: flex;
  .c-col {
    flex: 1;
    position: relative;
  }
}

.item-summary,
.horse-item {
  word-break: break-all;
}

.horse-item {
  .horse-img {
    img {
      max-width: 32px;
    }
  }
}

.marker-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  .pin {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 48px;
    height: 48px;
    display: block;
    text-align: center;
    line-height: 48px;
    font-size: 32px;
    margin-top: -48px;
    margin-left: -24px;
    .fa {
      position: relative;
      z-index: 5;
    }
    &:before {
      content: "";
      bottom: 6px;
      display: block;
      background: rgba(0, 0, 0, 0.9);
      width: 12px;
      height: 6px;
      position: absolute;
      left: 50%;
      margin-left: -6px;
      z-index: 1;
      filter: blur(3px);
    }
  }
}

.month-row {
  .month-col {
    width: 14.29%;
    flex: 1;
  }
}
.table {
  tr {
    td {
      position: relative;
    }
  }
}
.cell-box {
  position: absolute;
  width: 100%;
  .cell {
    height: 32px;
    + .cell {
      border-top: 1px dotted #ccc;
    }
  }
}
.order-boxes {
  position: relative;
  z-index: 5;
  height: 64px;
  display: flex;
  .order-box {
    min-height: 32px;
  }
}

.emergency-check {
  .form-check-input:checked {
    background-color: var(--bs-warning);
    border-color: var(--bs-warning);
  }
}

.react-datepicker {
  font-family: "Cabin", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  font-size: 12px !important;
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 1.75em;
    max-width: 2em;
  }
}
.react-datepicker-wrapper {
  display: block;
  width: 100%;
}

.calendar-object {
  .react-datepicker {
    width: 100%;
    border-radius: 0;
    border: none;
    border-top: 1px solid #ccc;
    .react-datepicker__month-container {
      float: none;
    }
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: 12%;
      max-width: 14%;
    }
  }
}
.c-cell {
  height: 64px;
  .cell {
    height: 32px;
    + .cell {
      border-top: 1px dotted #ccc;
    }
  }
}

.d-flex {
  img {
    align-self: center;
  }
}
