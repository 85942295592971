$enable-shadows: true;
$primary: #41815e;
$success: #2e7d32;
$secondary: #546e7a;
$warning: #f8a832;
$info: #ff9e7c;

@import "bootstrap/scss/bootstrap.scss";

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

:root {
  --xxs: 10px;
  --xs: 12px;
  --sm: 14px;
  --md: 16px;
  --lg: 18px;
  --xl: 20px;
  --xxl: 24px;
  --primary: var(--bs-primary);
  --primaryActive: var(--bs-primary);
  --white: var(--bs-white);
  --black: var(--bs-dark);
  --silver: var(--bs-gray);
  --grey: var(--bs-dark);
  --border: var(--bs-gray);
  --purple: var(--bs-purple);
  --orange: var(--bs-orange);
  --yellow: var(--bs-yellow);
  --red: var(--bs-red);
  --green: var(--bs-green);
}

body {
  font-family: "Source Sans Pro", "Montserrat", "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: #f3f6f9 url(../images/bg-luokki.jpg) no-repeat center;
  background-size: cover;
  font-weight: 300;
  background-attachment: fixed;
  color: var(--black);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Cabin", "Source Sans Pro", "Montserrat", "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: var(--black);
  font-weight: 400;
  strong {
    font-weight: 600;
  }
}
.btn {
  font-family: "Cabin", "Source Sans Pro", "Montserrat", "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
strong {
  font-weight: 600;
}

a {
  color: var(--primary);
}

.flex-container {
  display: flex;
}
.align-center {
  justify-content: center;
}
.align-justify {
  justify-content: space-between;
}
.align-middle {
  align-items: center;
}

.multisteps-form__progress {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
}

.multisteps-form__progress-btn {
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  transition-delay: 0s;
  position: relative;
  padding-top: 20px;
  color: #ccc;
  border: none;
  background-color: transparent;
  outline: none !important;
  cursor: pointer;
  font-size: var(--sm);
  text-transform: capitalize;
}

.multisteps-form__progress-btn:before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 16px;
  height: 16px;
  content: "";
  margin-left: -8px;
  transition: all 0.15s linear 0s, -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
  transition: all 0.15s linear 0s, transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
  transition: all 0.15s linear 0s, transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s,
    -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
  border: 2px solid currentColor;
  border-radius: 50%;
  background-color: #fff;
  box-sizing: border-box;
  z-index: 3;
}

.multisteps-form__progress-btn:after {
  position: absolute;
  top: 7px;
  left: calc(50% - 16px / 2);
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  transition-delay: 0s;
  display: block;
  width: 100%;
  height: 2px;
  content: "";
  background-color: #eee;
  z-index: 1;
}

.multisteps-form__progress-btn:last-child:after {
  display: none;
}

.multisteps-form__progress-btn.js-active {
  color: var(--primary);
  font-weight: bold;
  &:before {
    animation: pulse 1s infinite ease-in-out;
  }
  z-index: 5;
}

.multisteps-form__progress-btn.js-active:before {
  -webkit-transform: translateX(-50%) scale(1.2);
  transform: translateX(-50%) scale(1.2);
  background-color: currentColor;
}
.multisteps-form__progress {
  &.cancelled {
    .multisteps-form__progress-btn.js-active {
      color: var(--silver);
      &:before {
        animation: none;
        -webkit-transform: translateX(0) scale(1.2);
        transform: translateX(0) scale(1.2);
      }
    }
  }
}

.react-datetime-picker {
  input {
    color: #000;
  }
  .react-datetime-picker__wrapper {
    border-radius: 4px;
    border: 1px solid var(--silver);
  }
  .react-datetime-picker__inputGroup {
    padding: 0 8px;
  }
  .react-datetime-picker__button {
    color: var(--primary);
  }
}

body {
  min-height: 100vh;
}

// .btn-primary {
//   background: var(--primary);
//   border-color: var(--primary);
//   &:hover,
//   &:active {
//     background: var(--primaryActive);
//     border-color: var(--primaryActive);
//   }
// }
// .btn-outline-primary {
//   border-color: var(--primary);
//   color: var(--primary);
//   &:hover,
//   &:active {
//     border-color: var(--primaryActive);
//     background: var(--primaryActive);
//     color: var(--white);
//   }
// }

.btn {
  &.btn-round {
    border-radius: 300rem;
  }
}

/* modal css */

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  z-index: 1200;
  background: rgba(0, 0, 0, 0.3) !important;
  overflow: hidden;
  overflow-y: auto;
}

.reveal {
  width: 100%;
  max-width: 640px;
  margin: 100px auto 100px;
  padding: 2rem;
  background: #fff;
  position: relative;
  border-radius: 8px;
  outline: none;
  &.small {
    max-width: 480px;
  }
  &.medium {
    max-width: 520px;
  }
  &.special {
    overflow: visible;
  }
  &.dialog-modal {
    max-width: 360px;
    .button {
      margin: 0;
      color: #fff;
    }
    &.success {
      background: #efe;
    }
  }
  @media only screen and (max-width: 640px) {
    max-width: 90% !important;
    &.small {
      max-width: 90%;
    }
  }
}

.close-reveal {
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  font-size: 2rem;
  line-height: 32px;
  text-align: center;
  color: #999;
  z-index: 5;
  transform-origin: 50% 50%;
  background: none;
}

.opacity-100 {
  opacity: 1;
}
.opacity-75 {
  opacity: 0.75;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-25 {
  opacity: 0.25;
}
.opacity-0 {
  opacity: 0;
}

.size-10 {
  font-size: var(--xxs);
}
.size-12 {
  font-size: var(--xs);
}
.size-14 {
  font-size: var(--sm);
}
.size-16 {
  font-size: var(--md);
}
.size-18 {
  font-size: var(--lg);
}
.size-20 {
  font-size: var(--xl);
}
.size-24 {
  font-size: var(--xxl);
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-switch {
  padding-left: 3rem;

  .custom-control-label {
    margin: 0;

    &::before {
      position: absolute;
      top: 0;

      display: block;
      width: 1.5rem;
      height: 1.5rem;
      pointer-events: none;
      content: "";
      background-color: #fff;
      border: #adb5bd solid 1px;
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      right: 0;
      width: 2.75rem;
      pointer-events: all;
      border-radius: 1rem;
    }

    &::after {
      position: absolute;
      top: 0;

      display: block;
      width: 1.5rem;
      height: 1.5rem;
      content: "";
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
      top: 2px;
      right: calc(1.5rem - 2px);
      width: calc(1.5rem - 4px);
      height: calc(1.5rem - 4px);
      background-color: #adb5bd;
      border-radius: 1rem;
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-transform 0.15s ease-in-out;
      transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
      transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    }
  }

  .custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    &:checked {
      ~ .custom-control-label {
        &::after {
          background-color: #fff;
          -webkit-transform: translateX(1.25rem);
          transform: translateX(1.25rem);
        }
        &::before {
          color: #fff;
          border-color: var(--primary);
          background-color: var(--primary);
        }
      }
    }
  }
  &.custom-switch-left {
    .custom-control-label {
      &::before {
        left: 0;
        right: auto;
      }
      &::after {
        right: auto;
        left: calc(1.5rem - 2px);
        -webkit-transform: translateX(-1.25rem);
        transform: translateX(-1.25rem);
      }
    }
    .custom-control-input {
      &:checked {
        ~ .custom-control-label {
          &::after {
            background-color: #fff;
            -webkit-transform: translateX(0);
            transform: translateX(0);
          }
          &::before {
            color: #fff;
            border-color: var(--primary);
            background-color: var(--primary);
          }
        }
      }
    }
  }
}

.font-cabin {
  font-family: "Cabin", "Source Sans Pro", "Montserrat", "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.font-ssp {
  font-family: "Source Sans Pro", "Montserrat", "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.fs-14 {
  font-size: var(--sm);
}
.fs-12 {
  font-size: var(--xs);
}
.fs-10 {
  font-size: var(--xxs);
}

.label {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.gallery-container {
  position: relative;
}
.swiper-navigation {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -20px;
  z-index: 50;
  .swiper-nav {
    margin: 0;
    width: 40px;
    outline: none;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 300px;
    border: none;
    background: rgba(255, 255, 255, 0.5);
    color: var(--black);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.05);
  }
  &:hover {
    .swiper-nav {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    }
  }
}

.picker-right {
  .rdtPicker {
    right: 0;
  }
  input {
    text-align: center;
  }
}

.datetime-date,
.datetime-time {
  &[readonly] {
    background: #fff;
  }
}

.rdt-input-group {
  flex: 1;
  input {
    border-radius: 0 0.25rem 0.25rem 0;
  }
}
html,
body,
.app-container {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
}

.page-cta,
.main-menu {
  max-width: 600px;
  margin: 0 auto;
}

.calendar {
  table th {
    text-transform: capitalize;
  }
  table td {
    vertical-align: middle;
    h6 {
      text-transform: capitalize;
    }
  }
}

.luokki-calendar {
  .rdtPicker {
    width: 100%;
  }
  .rdtPicker td,
  .rdtPicker th {
    height: 40px;
    color: var(--black);
  }
  .rdtPicker td.rdtActive,
  .rdtPicker td.rdtActive:hover {
    background: none;
    color: var(--black);
  }
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  display: none;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  z-index: 4;
}
